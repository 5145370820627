// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'
import storage from 'redux-persist/lib/storage/'
// import storage from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist'

const persistConfig = {
    key: 'root',
    storage
    // storageSession
  }

// ** init middleware
const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistedReducer = persistReducer(persistConfig, rootReducer)
// ** Create store
// const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middleware)))

const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)))
export const persistor = persistStore(store)
export { store }
