// reducers.js

import { FETCH_DATA_FAILURE, SET_DATE, SET_DATE_PORTFOLIO, SET_EARNDATE, SET_ISNEXT, SET_LOADER, SET_NOTES, SET_PROFILE, SET_SHOW_NOTES, SET_STOCK_FIRST, SET_SUBS_DATA } from "../../actions/dashboard"
const stocksList = ['AAPL', 'SPY', 'GOOG', 'SAGE', 'UPST', 'META', 'INTL', 'C', 'HPQ', 'WFC', 'BAC', 'HP', 'AFL', 'AIG', 'AIV', 'AIZ', 'ALL', 'AMP', 'AON', 'AVB', 'AXP', 'BR', 'BEN', 'BK', 'CB', 'CINF', 'CMA', 'CME', 'CFG', 'COF', 'DFS', 'EFX', 'EQR', 'EL', 'FITB', 'GNW', 'GS', 'HBAN', 'HLT', 'HON', 'HCP', 'HIG', 'CE', 'IVZ', 'INVH', 'JPM', 'KEY', 'L', 'LLY', 'LNC', 'MCO', 'MET', 'MMC', 'S', 'MTB', 'NDAQ', 'NTRS', 'NXPI', 'PFG', 'PGR', 'PLD', 'PNC', 'PRU', 'SLM', 'STT', 'T', 'TROW', 'TRV', 'UNM', 'USB', 'VTR', 'XOM']
const username = localStorage.getItem('username')
const savedObj = JSON.parse(localStorage.getItem(`${username}_Data`))


const initialState = {
  date: null,
  port_date : null,
  first_stock : null,
  earnDates: [],
  ticker : stocksList,
  dashProfileData: {},
  error: null,
  loader : true,
  notes_title : "",
  notes : "",
  showNotes : false,
  subscription_data : null,
  isNextDay : true

}

const dateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATE:
      return {
        ...state,
        date: action.payload
      }
      case SET_PROFILE:
      return {
        ...state,
        dashProfileData: action.payload
      }

      case SET_EARNDATE:
      return {
        ...state,
        // earnDates: [...state.earnDates, action.payload],
        earnDates: action.payload,

        error: null
      }
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        error: action.payload
      }

      case SET_LOADER:
        return {
          ...state,
          loader: action.payload
        }
      case SET_NOTES:
        return {
          ...state,
          notes: action.payload.notes,
          notes_title: action.payload.title
        }
        case SET_SHOW_NOTES:
          return {
            ...state,
            showNotes: action.payload
          }   
        case SET_SUBS_DATA:
          return {
            ...state,
            subscription_data: action.payload
          } 
          case SET_ISNEXT:
          return {
            ...state,
            isNextDay: action.payload
          } 
          case SET_STOCK_FIRST:
          return {
            ...state,
            first_stock: action.payload
          }   
          case SET_DATE_PORTFOLIO:
          return {
            ...state,
            port_date: action.payload
          }           
    default:
      return state
  }
}

export default dateReducer
