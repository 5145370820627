import axios from "axios"

export const SET_DATE = 'SET_DATE'
export const SET_EARNDATE = 'SET_EARNDATE' // in graphs dropdown
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_LOADER = 'SET_LOADER'
export const SET_NOTES = 'SET_NOTES'
export const SET_SHOW_NOTES = 'SET_SHOW_NOTES'
export const SET_SUBS_DATA =  'SET_SUBS_DATA'
export const SET_ISNEXT = "SET_ISNEXT"
export const SET_STOCK_FIRST = 'SET_STOCK_FIRST'
export const SET_DATE_PORTFOLIO = 'SET_DATE_PORTFOLIO'

export const setDate = (date) => ({
  type: SET_DATE,
  payload: date
})

export const setDateFromPortfolio = (date) => ({
  type: SET_DATE_PORTFOLIO,
  payload: date
})

export const setStock = (stock) => ({
  type: SET_STOCK_FIRST,
  payload: stock
})

export const setMainLoader = (data) => ({
  type: SET_LOADER,
  payload: data
})

export const setIsNextDay = (payload) => ({
  type : SET_ISNEXT,
  payload: payload
})

export const setShowNotes = (data) => ({
  type: SET_SHOW_NOTES,
  payload: data
})
export const setNotesData = (data) => ({
  type: SET_NOTES,
  payload: data
})

export const setSaveDashProfiles = (payload) => ({
  type: SET_PROFILE,
  payload: payload
})

export const setAllLatestEarnDates = (data) => ({
  type: SET_EARNDATE,
  payload: data
})

export const setSubscriptionData = (data) => ({
  type: SET_SUBS_DATA,
  payload: data
})

export const setFetchError = (data) => ({
  type: FETCH_DATA_FAILURE,
  payload: data
})

const stocksList = ['AAPL', 'SPY', 'GOOG', 'UPST', 'SAGE', 'BAC', 'WFC', 'C', 'HPQ', 'INTL']

export const fetchSubscriptionData = () => {
  // stocksList.map(ticker => {
  return async (dispatch) => {
    const getData_server = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/get-user-subs-details`,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    })

    try {
      const response = await getData_server.get('').then(res => {
        if (res.status === 200) {
          dispatch(setSubscriptionData(res.data))
          console.log("subscription_data", res.data)
        }

      })
    } catch (err) {
      dispatch(setFetchError(err.message))
    }
  }
}

export const getAllLatestEarnDates = ({ tickers, end_date, signal }) => {
  // stocksList.map(ticker => {
  return async (dispatch) => {

    const payload = {
      stock_tickers: tickers,
      end_date: end_date
    }
    const getData_server = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/all-earnings`,
      signal: signal
    })

    try {
      const response = await getData_server.get('').then(res => {
        if (res.status === 200) {
          // console.log('earning data', res.data)
          const resData = res.data
          // const resDataS = ` - (${resData})`
          dispatch(setAllLatestEarnDates({ data: resData.data }))
          // setStockE(`${ticker}${resDataS}`)
          // console.log('from redux', resData.data.length, resData.data)
        }
      })

    } catch (err) {
      dispatch(setFetchError(err.message))
    }

  }
  // })

}